@import '../../styles/customMediaQueries.css';
.shopSmallContainer {
  background-color: var(--matterColorLight);
  padding: 50px 0;
  & .fixWidthContainer {
    max-width: calc(1200px + 24px + 24px);
    padding: 0px 24px;
    margin: 0 auto;
  }
}
.sellerCards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  @media (min-width: 375px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportSmall) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(5, 1fr);
  }
  & > div {
    & > a {
      & > div {
        &:first-child {
          width: 80px;
          height: 80px;

          @media (--viewportSmall) {
            width: 100px;
            height: 100px;
          }
          @media (--viewportMedium) {
            width: 150px;
            height: 150px;
          }
        }
      }
    }
  }
}

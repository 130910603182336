@import '../../../styles/customMediaQueries.css';

.sellerCard {
  background-color: var(--matterColorLight);
  transition: all ease 0.5s;
  padding: 8px;
  width: 100%;
  &:hover {
    transition: all ease 0.5s;
  }

  & .rating {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    top: -12px;
    font-size: 14px;

    & > svg {
      width: 14px;
      margin-right: 5px;

      & > path {
        fill: var(--attentionColor);
      }
    }
  }

  & a {
    display: inline-flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;

      & .sellerImg {
        & img {
          transition: all ease 0.5s;
          transform: scale(1.05);
        }
      }
    }

    & .sellerImg {
      width: 19%;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;
      position: relative;
      border: solid 1px var(--matterColorNegative);

      & img {
        max-width: 100%;
        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: all ease 0.5s;
        transform: scale(1);
      }
    }

    & .sellerName {
      font-size: 14px;
      font-weight: 600;
      color: var(--matterColor);
      margin: 0 0 8px 0;
      line-height: 130%;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }

    & .sellerinfo {
      font-size: 12px;
      font-weight: 400;
      color: var(--matterColor);
      margin: 0;
      line-height: 130%;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-align: center;
    }
  }
}

.sellerInitials {
}

.initials {
  padding: 62px;
  background: var(--marketplaceColor);
  color: var(--matterColorLight);
  position: relative;
  top: 58px;
  border-radius: 50%;

  @media screen and (max-width: 768px) {
    padding: 50px;
  }
}

.sellerNameInit {
  position: relative;
  top: 134px;
  @media screen and (max-width: 768px) {
    top: 120px;
  }
}

.sellerInfoInit {
  position: relative;
  top: 135px;
  font-size: 14px;
  font-weight: 600;
  color: var(--matterColor);
  margin: 0 0 8px 0;
  line-height: 130%;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;

  @media screen and (max-width: 768px) {
    top: 120px;
  }
}

/* Define styles for screens up to 425px */
@media (max-width: 425px) {
  .cardSkeleton {
    width: 100%;
    height: auto;
  }
}

/* Define styles for screens between 425px and 768px */
@media (min-width: 425px) and (max-width: 768px) {
  .cardSkeleton {
    width: 90%;
    max-width: 600px;
    height: auto;
  }
}

/* Define styles for screens between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .cardSkeleton {
    width: 90%;
    max-width: 800px;
    height: auto;
  }
}

/* Define styles for screens above 1024px */
@media (min-width: 1024px) {
  .cardSkeleton {
    width: 100%;
    max-width: 1200px;
    height: auto;
  }
}
